var
    $panoWrapper = $('#panoWrapper'),
    $pinsWrapper = $('#pins-wrapper'),
    panoId = 'pano',
    panoHTML = '<div class="pano" id="' + panoId + '"></div>',
    panoOptions = {
        swf: './media/pano/videopano.swf',
        xml: './media/pano/xml/videopano_0.xml',
        target: panoId,
        html5: 'prefer',
        bgcolor: 'transparent',
        mobilescale: 1.0,
        passQueryParameters: true
    };


// Start pano
function startPano() {
    $panoWrapper.append(panoHTML);
    console.log('Start pano');
    embedpano(panoOptions);
};

// On loaded
function panoLoaded() {
    setTimeout(function () {
        $panoWrapper.addClass('pano-wrapper_transition');
    }, 100);


    setTimeout(function () {
        $panoWrapper.addClass('pano-wrapper_loaded');
        // @TODO: сделать багфикс по выделению активного хотспота 
        // setActiveHotspot(getCurrentLocationID());
    }, 600);
}

// Show pano loader
function showPanoLoader() {
    var $panoLoader = $('#panoLoader');
    $panoWrapper.removeClass('pano-wrapper_transition pano-wrapper_loaded');
    $panoLoader.removeClass().addClass('pano-loader');
}


// Crate hotspot
function createhotspot(hotspot, ath, atv, callback) {

    var
        spotX = krpano().get('spotx'),
        spotY = krpano().get('spoty'),
        verticalOffset = 40,
        krpanoObj = document.getElementById('krpanoSWFObject'),
        hotspotWrapper = document.getElementById('pins-wrapper'),
        $hotspotWrapper = $('#pins-wrapper'),
        panoPin = document.createElement('a'),
        hotspotID = parseInt(hotspot.replace("hotspot", ''));

    panoPin.setAttribute('data-ath', ath);
    panoPin.setAttribute('data-atv', atv);
    panoPin.setAttribute('id', hotspot);
    panoPin.setAttribute('id', hotspot);
    panoPin.setAttribute('name', hotspot);
    panoPin.setAttribute('data-pin', hotspotID);
    panoPin.setAttribute('href', '#');
    panoPin.style.left = spotX + 'px';
    panoPin.style.top = (spotY - 70) +'px';
    panoPin.className = 'pins__item';

    hotspotWrapper.appendChild(panoPin);

}

function panoramaMoved(hotspot) {
    var
        spotX = krpano().get('spotx'),
        spotY = krpano().get('spoty'),
        hot = document.getElementById(hotspot);

    hot.style.left = spotX + 'px';
    hot.style.top = (spotY - 70) + 'px';
}

// Set active hotspot
function setActiveHotspot(settingID) {
    var
        ath = $('[data-pin=' + settingID + ']').data('ath'),
        atv = $('[data-pin=' + settingID + ']').data('atv'),
        krpano = document.getElementById("krpanoSWFObject");


    console.log('atv: ' + atv);
    console.log('set Active Hotspot: ' + settingID);

    

    $('.pins__item').removeClass('active');
    $('[data-pin=' + settingID + ']').addClass('active');


    krpano.call('moveto(' + ath + ', ' + atv + ', smooth(200,50,20) )');
    // krpano.call('moveto(' + ath + ', ' + atv + ')');

}

// Old functions
function krpano() {
    return document.getElementById("krpanoSWFObject");
}


// Start Panorama
startPano();
