// Timelapse video

// Start timelapse video function
function startTimelapse() {
    // console.log('Start timelapseVideo');
    timelapseVideo.Background(timelapseBackgroundedOptions);

    timelapseVideo.play();
    return false;
};


$.preloadImages = function() {
  for (var i = 0; i < arguments.length; i++) {
    $("<img />").attr("src", arguments[i]);
  }
}


// for (var i = 0; i < 31; i++) {
//     var imgPath = '/media/pano/pano-photos/' + i + '.jpg';
//     $.preloadImages(imgPath);
// }
