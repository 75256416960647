// Intro video
$(function () {



    // Start intro video first time
    startIntroVideo.click(function () {

        if (isSoundEnabled()) {
            introBackgroundedOptions['volume'] = 1;
            console.log('Интро будет со звуком');
        }
        else {
            introBackgroundedOptions['volume'] = 0;
            console.log('Интро будет без звука');
        };

        // Hide and remove start section
        startContainer.addClass('start_intro-video-started');

        // Waiting for transition ands
        setTimeout(function () {
            startContainer.remove(); // remove start container
        }, 300);

        // Make video backgrounded
        introVideo.Background(introBackgroundedOptions);


        // Start intro videp
        introVideo.play();
        return false;

    });

    // Skip intro video
    skipIntro.click(function () {
        return introFinished();
    });

    // Show Intro Again
    $showIntroAgain.click(function () {

        // Pause audio
        pauseAudio();

        // Hide main controls
        mainConteiner.addClass('main_hidden-controls');

        // remove display:none for working transition
        introConteiner.removeClass('intro__hidden').addClass('intro_transition');


        // Waiting for transition ands
        setTimeout(function () {

            introConteiner.removeClass('intro_transition');

            if (isSoundEnabled()) {
                introBackgroundedOptions['volume'] = 1;
                console.log('Интро будет со звуком');
            }
            else {
                introBackgroundedOptions['volume'] = 0;
                console.log('Интро будет без звука');
            };

            // Make video backgrounded
            introVideo.Background(introBackgroundedOptions);

            // Start intro videp
            introVideo.play();


        }, 100);

    });



});



// Intro finished funtion
function introFinished() {

    // Start Timelapse
    startTimelapse();


    // Animating intro video backgrounded
    introConteiner.addClass('intro_transition');

    // Showing main container
    mainConteiner.removeClass('main_hidden-controls');


    // Waiting for transition ends
    setTimeout(function () {

        // Hide intro video backgrounded
        introConteiner.addClass('intro__hidden').removeClass('intro_transition');

        // Set intro video to beginning
        introVideo.currentTime(0);

        // Pause intro video
        introVideo.pause();

        // Show poster
        introVideo.posterImage.show();


    }, 300);

    // Waiting for start audio
    setTimeout(function () {

        if (isSoundEnabled()) {
            playAudio();
        }
        else {
            pauseAudio();
        };

    }, 1000);

    console.log('Intro closed!');

    return false;
}