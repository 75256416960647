$(function() {
    $burger.on('click', function() {

        if ($('#main').hasClass('main_sidebar-open')) {

            // Close sidebar
            var $pinsWrapper = $('#pins-wrapper');

            $('#main').removeClass('main_sidebar-open');
            timelapseVideo.play();

            // Make pano transition
            setTimeout(function() {

                // Remove display block to panorama
                $panoWrapper.removeClass('pano-wrapper_block');
                showPanoLoader();

            }, 600);


        } else {

            // Show sidebar

            // Pause timelapse video
            timelapseVideo.pause();

            // Get timestamp parametrs for loading panorama XML
            var $panoLoader = $('#panoLoader');
            var timelapseVideoPosition = Math.round(timelapseVideo.currentTime()); // 1-31 sec (int)
            var krpano = document.getElementById("krpanoSWFObject");
            var newPanoXML = '/media/pano/xml/videopano_' + timelapseVideoPosition + '.xml';
            var $pinsWrapper = $('#pins-wrapper');

            
            $panoLoader.addClass('pano-loader_' + timelapseVideoPosition );

            console.log(timelapseVideoPosition);

            $('#main').addClass('main_sidebar-open');

            setTimeout(function() {
                krpano.call("loadpano(" + newPanoXML + ");");
                $pinsWrapper.html('');

            }, 600);

        }
        return false;
    });
});
