var places = $('.places');

// Click on hotspot
$('body').on('click', '.pins__item', function() {

    var
        that = $(this),
        settingID = that.data('pin');
    
    

    // Mark pin active
    $('.pins__item').removeClass('active');
    that.addClass('active');

    // Show sidebar if closed
    if (!isSidebarOpened()) {
        $('#main').addClass('main_sidebar-open');
        console.log('open sidebar');
    }

    // Set location
    setLocation(settingID);
});

// Click next location
$('#nextLocation').on('click', function () {
    nextLocation();
});

// Click previous location
$('#prevLocation').on('click', function () {
    prevLocation();
})


// Get current location
function getCurrentLocationID() {
    var currentLocation = places.find('.active');
    return currentLocation.data('location');
}

// Set location
function setLocation(settingID) {

    var
        currentID = getCurrentLocationID(),
        currentLocation = places.find('[data-location=' + currentID + ']'),
        settingLocation = places.find('[data-location=' + settingID + ']');

    if (settingID > 20 || settingID < 1 || currentID === settingID || isNaN(settingID) || settingID === true) {
        return false;
    }

    currentLocation.addClass('hiding');
    settingLocation.css('display','block');
    setTimeout(function () {
        settingLocation.addClass('showing').removeAttr('style');
    }, 50);



    updateSwitcher(settingID);

    // Waiting for transition ends
    setTimeout(function () {
        currentLocation.removeClass('active').removeClass('hiding');
        settingLocation.addClass('active').removeClass('showing');
        $('.switcher__arrow').removeClass('disabled');
    }, 300);


    setActiveHotspot(settingID);

    console.log(currentID + ' -> ' + settingID);
    return currentID + ' -> ' + settingID;
}


function updateSwitcher(settingID) {
    var offset = ((settingID - 1) * 40) + 'px';

    $('.switcher').find('.values').css('top', '-' + offset);
    // console.log(offset);
    return offset;
}

// Previous location
function nextLocation() {
    var settingID,
        currentID = getCurrentLocationID();

    $('.switcher__arrow').addClass('disabled');

    if (currentID === 20) {
        settingID = 1
    }
    else {
        settingID = currentID + 1
    };

    return setLocation(settingID);
}


// Next location
function prevLocation() {
    var settingID,
        currentID = getCurrentLocationID();

    $('.switcher__arrow').addClass('disabled');

    if (currentID === 1) {
        settingID = 20
    }
    else {
        settingID = currentID - 1
    }

    return setLocation(settingID);
}

// Is sidebar opened
function isSidebarOpened() {
    return $('#main').hasClass('main_sidebar-open');
}
