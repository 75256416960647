var
    // Containers and elements
    startContainer = $('#start'),           // start container
    introConteiner = $('#intro'),           // intro container
    mainConteiner = $('#main'),             // main container
    startIntroVideo = $('#startIntro'),     // btn starting intro video
    $showIntroAgain = $('#showIntroAgain'), // btn in the bottom bar
    skipIntro = $('#skipIntro'),            // btn stopping intro video
    $burger = $('#findOutMoscow'),          // burger
    windowWidth = $(window).width(),        // window width
    audio = document.getElementById('audio'),
    $soundSwitch = $('.soundSwitch'),


    // Intro video options
    introVideoSize = windowWidth > 1023 ? '1920' : '720';
    introOptions = {
        "controls": false,
        "nativeControlsForTouch": false,
        "customControlsOnMobile": true,
        "autoplay": false,
        "aspectRatio": "16:9",
        "preload": "auto",
        "muted": false,
        "loop": false,
        "poster": "./img/poster__intro.jpg",
        "sourceOrder": true,
        "sources": [
            // {
            //     // new HLS
            //     src: './media/intro/' + introVideoSize + '/stream/intro.m3u8',
            //     type: 'application/x-mpegURL'
            // },
            {
                // new MP4
                src: './media/intro/' + introVideoSize + '/intro.mp4',
                type: 'video/mp4'
            },
            {
                // new WEBM
                src: './media/intro/' + introVideoSize + '/intro.webm',
                type: 'video/webm'
            }
        ]
    },
    introBackgroundedOptions = {
        "autoPlay": false,
        "container": document.getElementById('intro'),
        "mediaAspect": 16 / 9
    },

    // Init intro video and add callback
    introVideo = videojs('#introVideo', introOptions).ready(function() {
        this.on("ended", function() {
            introFinished();
        });
    }),

    // Timelapse Video options
    timelapseOptions = {
        "controls": false,
        "nativeControlsForTouch": false,
        "customControlsOnMobile": true,
        "autoplay": false,
        "aspectRatio": "1920:896",
        "preload": "auto",
        "muted": true,
        "loop": true,
        // "poster": "./img/start-bg.jpg",
        "sourceOrder": true,
        "sources": [
            // {
            //     // HLS
            //     src: './media/timelapse/timelapse.m3u8',
            //     type: 'application/x-mpegURL'
            // },
            {
                // MP4
                src: './media/timelapse/timelapse.mp4',
                type: 'video/mp4'
            },
            {
                // WEBM
                src: './media/timelapse/timelapse.webm',
                type: 'video/webm'
            }
        ]
    },

    timelapseBackgroundedOptions = {
        "autoPlay": false,
        "container": document.getElementById('timelapseWrapper'),
        "mediaAspect": 30 / 14
    },

    // Init timelapse video and add callback
    timelapseVideo = videojs('#timelapseVideo', timelapseOptions);