// Audio

function playAudio() {
    console.log('Врубаем музыку 🔉');
    audio.play();
}

function pauseAudio() {
    console.log('Вырубаем музыку 🔇');
    audio.pause();
}
