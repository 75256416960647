$(function() {
    $('a[data-overlay]').on('click', function(e) {

        var
            $this = $(this),
            $target = $this.data('overlay'),
            $overlay = $('#' + $target),
            $mainConteiner = $('#main'); // main container

        if ($overlay.length == 0) {
            // it doesn't exist
            console.error('Overlay container "' + $target + '" not found');
            return false;
        }


        // remove display:none for working transition
        $overlay.addClass('overlay_transition');

        // hide controls in main container
        $mainConteiner.addClass('main_hidden-controls');

        // Waiting for transition ands
        setTimeout(function() {
            // show overlay
            $overlay.addClass('overlay_active');

        }, 300);

        return false;

    });

    // Close overlay
    $('.overlay__close').on('click', function(e) {
        
        console.log('close overlay');

        var
            $this = $(this),
            $overlay = $this.closest('.overlay'),
            $mainConteiner = $('#main'); // main container

        // remove active class to start transition
        $overlay.removeClass('overlay_active');

        // Show controls in main container
        $mainConteiner.removeClass('main_hidden-controls');

        // Waiting for transition ands
        setTimeout(function() {
            // Remove temp transition class
            $overlay.removeClass('overlay_transition');
        }, 300);

        return false;

    });



});
