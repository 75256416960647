// Sound switcher
;
$(function () {

    // Переключаем иконку
    if (isSoundEnabled()) {
        $soundSwitch.removeClass('sound_off');
    }
    else {
        $soundSwitch.addClass('sound_off');
    }


    $soundSwitch.click(function () {

        var
            $this = $(this),
            audioSwitcher = $this.attr('data-audio');

        // Если звук включен
        if (isSoundEnabled()) {

            // ВЫключаем 🔇
            pauseAudio();
            Cookies.set('sound', 'off');
            $soundSwitch.addClass('sound_off');
            console.log('Выключаем звук 🔇 : ' + Cookies.get('sound'));

        }
        else {

            if ( audioSwitcher ) {
                playAudio();
            }

            // Включаем 🔈
            Cookies.set('sound', 'on');
            $soundSwitch.removeClass('sound_off');
            console.log('Включаем звук 🔈 : ' + Cookies.get('sound'));
        };
    });

});


function isSoundEnabled() {
    var sound = Cookies.get('sound');

    if (sound === undefined || sound === 'on') {
        Cookies.set('sound', 'on');
        return true;
    }
    else {
        return false;
    }

}
