
$(function () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


    console.log('IOS: ' + isIOS);


    if (isIOS) {
        webkitExitFullscreen();
        console.log('IT is IOS: fullscreen disabled' + isIOS);
    }


})
